import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';

import Advertisement from 'src/pages/AdvertisementPage';
import withAppStateContext from 'src/components/AppStateProvider/withAppStateContext';
import LoginPage from 'src/pages/LoginPage';
import CenterPage from 'src/pages/CenterPage';
import NewebPayPage from 'src/pages/NewebPayPage';
import NotFoundPage from 'src/pages/NotFoundPage';
import InActivePage from 'src/pages/InActivePage';
import PayErrorPage from 'src/pages/PayErrorPage';
import PlanService from 'src/services/plans';
import GlobalModal from 'src/components/Modal';
import AppLabel from 'src/components/AppLabel';
import MobileHorizontalAlert from './components/MobileHorizontalAlert';
import Loading from './components/Loading';
import { preventZoom } from './utils';

import CommBundle from './components/CommBundle';

import NavBar from './components/NavBar';
import NavBarViewModel from './viewModels/NavBarVIewModel';
import './App.css';

const LoadablePickOutPage = Loadable({
  loader: () => import('src/pages/PickOutPage'),
  loading: Loading,
  delay: 300,
  timeout: 30000
});

const LoadablePreOrderPage = Loadable({
  loader: () => import('src/pages/PreOrderPage'),
  loading: Loading,
  delay: 300,
  timeout: 30000
});

const LoadableOrderPage = Loadable({
  loader: () => import('src/pages/OrderPage'),
  loading: Loading,
  delay: 300,
  timeout: 30000
});

// const LoadalbeAdvertisementPage = Loadable({
//   loader: () => import('src/pages/AdvertisementPage'),
//   loading: Loading,
//   delay: 300,
//   timeout: 30000
// });

@observer
class App extends React.Component {
  constructor(props) {
    super(props);

    this.navBarViewModel = new NavBarViewModel();
  }

  async componentDidMount() {
    preventZoom();

    try {

      // NOTE: redirect for old domain.
      const arr = window.location.hostname.split('.');
      const domain = arr[arr.length - 2];

      if (domain === 'subtle-net') {
        const { protocol, pathname, search } = window.location;
        const subdomain = arr.slice(0, arr.length - 2).join('.');

        window.location.replace(`${protocol}//${subdomain.length ? `${subdomain}.` : ''}maauu.com${pathname}${search}`);
      }
    } catch (err) {
      // ignore
    }

    // remove mask (if not puppeteer)
    if (navigator.userAgent !== 'puppeteer') {
      const mask = document.body.querySelector('#mask');
      if (mask) {
        document.body.removeChild(mask);
      }
    }

    const { actions } = this.props.appContext;

    try {
      const plan = await PlanService.getPlanInfo();
      actions.updatePlan(plan);

      if (!plan.isActive && window.location.pathname !== '/403') {
        window.location.replace('/403');
      }

    } catch (err) {
      console.warn('Failed to update plan in context');
      actions.updatePlan({});
    }
  }

  render() {
    return (
      <>
        <AppLabel />

        <Router basename="/">
          <NavBar viewModel={this.navBarViewModel} />
          <Switch>
            <Route path="/pickout">
              <LoadablePickOutPage />
            </Route>
            <Route path="/order/:id">
              <LoadableOrderPage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/center">
              <CenterPage />
            </Route>
            <Route path="/preorder">
              <LoadablePreOrderPage />
            </Route>
            <Route path="/newebpay/:uid/:pid">
              <NewebPayPage />
            </Route>
            <Route path="/404">
              <NotFoundPage />
            </Route>
            <Route path="/403">
              <InActivePage />
            </Route>
            <Route path="/payerror">
              <PayErrorPage />
            </Route>
            <Route path="/">
              <Advertisement
                handleChangeSection={this.navBarViewModel.handleChangeSection}
              />
            </Route>
          </Switch>
        </Router>

        <CommBundle />
        <GlobalModal />
        <MobileHorizontalAlert />

      </>
    );
  }
}

App.propTypes = {
  appContext: PropTypes.object
};

App.defaultProps = {
  appContext: null
};

export default withAppStateContext(App);
